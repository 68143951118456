import {
  IconButton, Link, Stack, Toolbar,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  Root,
  Header,
  EdgeTrigger,
  Scheme,
  Footer,
  Content,
} from '@mui-treasury/layout';
import { KeyboardArrowLeft, Menu } from '@mui/icons-material';
import { bindTrigger } from 'material-ui-popup-state';
import PrimaryNavigationDrawer from '../../components/app/PrimaryNavigationDrawer';
import Spacer from '../../components/app/Spacer';
import UserMenu from '../../components/app/UserMenu';
import MainMobileNavigation from '../../components/layouts/MainMobileNavigation';
import IconLogoWhite from '../../components/icons/IconLogoWhite';
import AppContent from '../../components/app/Content';
import Application from '../../components/app/Application';
import UserAvatar from '../../components/app/UserAvatar';

const scheme: Scheme = {
  header: {
    config: {
      xs: {
        position: 'sticky',
        height: 56,
      },
      md: {
        position: 'sticky',
        top: 0,
        height: 64,
        clipped: true,
      },
    },
  },
  leftEdgeSidebar: {
    config: {
      xs: {
        variant: 'temporary',
        width: 256,
      },
      md: {
        variant: 'permanent',
        width: 256,
        collapsible: true,
        collapsedWidth: 64,
      },
    },
  },
};

const BaseDashboardLayout: FC<{ children: ReactNode; }> = ({ children }) => (
  <Application>
    <Root scheme={scheme}>
      <Header color="primary">
        <Toolbar>
          <Stack columnGap={1} flexDirection="row" alignItems="center">
            <EdgeTrigger target={{ anchor: 'left', field: 'open' }}>
              {(open, setOpen) => (
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowLeft htmlColor="#fff" /> : <Menu htmlColor="#fff" />}
                </IconButton>
              )}
            </EdgeTrigger>
            <UserMenu>
              {(popupState) => (
                <IconButton
                  {...bindTrigger(popupState)}
                  size="small"
                  aria-controls={popupState.isOpen ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={popupState.isOpen ? 'true' : undefined}
                >
                  <UserAvatar />
                </IconButton>
              )}
            </UserMenu>
          </Stack>

          <Spacer />
          <Link href="/"><IconLogoWhite width={150} /></Link>
          <Spacer />
        </Toolbar>
      </Header>

      <PrimaryNavigationDrawer />

      <AppContent component="div">
        <Content sx={{ height: '100%', '& > div': { height: '100%' } }}>
          {children}
        </Content>
      </AppContent>

      <Footer sx={{ marginTop: { xs: '56px', lg: 0 } }}>
        <MainMobileNavigation />
      </Footer>
    </Root>
  </Application>
);

export default BaseDashboardLayout;
