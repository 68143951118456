import { useLocation } from '@reach/router';
import {
  FC, useCallback, useMemo,
} from 'react';

import Divider from '@mui/material/Divider';
import {
  ButtonBase,
  Link, List, ListItemButton, ListItemIcon, ListItemText, Toolbar,
} from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { EdgeSidebar, SidebarContent, EdgeTrigger } from '@mui-treasury/layout';
import { KeyboardArrowRight, KeyboardArrowLeft, Settings } from '@mui/icons-material';

const PrimaryNavigationDrawer: FC = () => {
  const { pathname } = useLocation();

  const dashboardNavLinks = useMemo(() => [
    {
      text: 'Publications',
      icon: LibraryBooksIcon,
      href: 'publications',
      match: pathname.match(/^\/app\/dashboard\/publications/),
    },
    {
      text: 'Settings',
      icon: Settings,
      href: 'settings',
      match: pathname.match(/^\/app\/dashboard\/settings\/*/),
    },
    {
      text: 'Integrations',
      icon: AccountTreeIcon,
      href: 'integrations',
      match: pathname.match(/^\/app\/dashboard\/integrations/),
    },
  ], [pathname]);

  const renderDashboardDrawer = useCallback(() => (
    <>
      <List>
        {dashboardNavLinks.map((item) => (
          <ListItemButton
            selected={!!item.match}
            component={Link}
            key={item.text}
            href={`/app/dashboard/${item.href}`}
          >
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </>
  ), [dashboardNavLinks]);

  return (
    <EdgeSidebar anchor="left">
      <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }} />
      <SidebarContent>{renderDashboardDrawer()}</SidebarContent>
      <EdgeTrigger target={{ anchor: 'left', field: 'collapsed' }}>
        {(collapsed, setCollapsed) => (
          <ButtonBase
            onClick={() => setCollapsed(!collapsed)}
            sx={{
              minHeight: 40,
              width: '100%',
              bgcolor: 'grey.100',
              borderTop: '1px solid',
              borderColor: 'grey.200',
            }}
          >
            {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </ButtonBase>
        )}
      </EdgeTrigger>
    </EdgeSidebar>
  );
};

export default PrimaryNavigationDrawer;
